<template>
  <div id="tools" v-if="items.length > 0">
    <v-row>
      <v-col cols="12" sm="6" lg="4" v-for="(item, index) in items" :key="index">
        <v-card class="mx-auto" elevation="0" outlined>
          <v-img
            class="white--text align-end"
            height="200px"
            :src="item.image || 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.6)"
          >
            <v-card-title>{{ item.title }}</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-2">Updated at: {{ item.updated | formatDate }}</v-card-subtitle>
          <v-card-text class="text--primary pb-0">
            <div class="max-line">{{ item.description }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" text :to="'/tools/' + item.slug">点击跳转</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Tools",

  data: () => ({
    items: [],
  }),

  mounted() {
    this.$axios.get("/api/tools/").then(res => (this.items = res.data));
  },
};
</script>

<style scoped>
.max-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* number of lines to show */
}
</style>